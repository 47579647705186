@import "./ui/Theme.scss";

.starter-list .card {
    background-color: $starter-list-card-bg-color;
    border: none;
    border-radius: 5px;
    border: solid 1px $starter-list-card-border-color;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 30px $starter-list-card-box-shadow-color;
}

.starter-list .card .card-body{
    padding: 0px;
}

.starter-list table {
    border-style: hidden;
    border-radius: 5px;
}

.starter-list table th{
    border-top: none;
    border-bottom: none;
    font-weight: 600;

    background-color: $starter-list-table-th-bg-color;
    color: $starter-list-table-th-color;
    padding: 20px 20px;

}

.starter-list table tr {
    flex: 1
}

.starter-list table tr th:first-child {
    border-top-left-radius: 3px;
}

.starter-list .btn-group-starter {
    width: 200px;
    height: 31px;
}

.starter-list .starter-list-column-icon {
    width: 100%;
    height: 48px;
    border-radius: 5%;
}

.starter-list table tr th:last-child {
    border-top-right-radius: 3px;
}

.starter-list .search-form .input-group-text{
    background-color: $starter-list-search-form-input-group-text-bg-color;
    border-right: none;
}

.starter-list .search-form .input-group-prepend+input.form-control {
    border-left: none;
    padding-left: 0px;
}

.starter-list table tr.starter-list-item-row td {
    padding: 0px;
    background-color: $starter-list-table-tr-starter-list-item-row-td-bg-color;
}

.starter-list table tr.starter-list-item-row:nth-child(odd) td {
    background-color: $starter-list-table-tr-starter-list-item-row-td-odd-bg-color;
}

.starter-list table tr.starter-list-item-row td a {
    text-decoration: none;
    padding: 20px 20px;
    color: $starter-list-table-tr-starter-list-item-row-td-a-color;
}

.starter-list table tr.starter-list-item-row td p {
    cursor: pointer
}

.starter-list table tr.starter-list-item-row td p {
    text-decoration: none;
    padding: 20px 20px;
    color: $starter-list-table-tr-starter-list-item-row-td-a-color;

}

.starter-list table tr:hover td {
    background-color: $starter-list-table-tr-hover-td-bg-color!important;
}

.starter-list {
    .starter-select {
        margin-right: 4px;
    }
}

td, th {
    text-align: center;
    vertical-align: middle;
}

td {
    cursor: pointer;
}
