@import "./Theme.scss";

#sidebar .menu{
    width: 100%;

    .notification {
        margin-left: 10px;
        width: 20px;
        display: inline-block;
        text-align: center;
        height: 20px;
        background: red;
        border-radius: 50%;
        font-size: 10px;
        color: white;
        padding: 1px 4px 0 3px;
    }
}

#sidebar .menu a.nav-link, #sidebar .menu .menu-separator {
    display: block;
    width: 100%;
    line-height: 20px;
    padding: 12px 20px;
    color: $nav-link-color;
}

#sidebar .menu .menu-separator svg {
    opacity: 0;
    width: 30px;
    height: 17px;;
    position: absolute;
    transition: opacity .25s ease-out;
}

#sidebar:not(.sidebar-open-xl) .menu .menu-separator svg {
    opacity: 1;
}

#sidebar.sidebar-open-xl .menu .menu-separator span {
    opacity: 1;
    transition: opacity .25s ease-out;
}

#sidebar:not(.sidebar-open-xl) .menu .menu-separator span {
    opacity: 0;
    transition: opacity .25s ease-out;
}

#sidebar .menu a.nav-link svg {
    width: 30px;
    margin-right: 10px;
}

#sidebar .menu a.nav-link.active {
    color: $nav-link-active-color;
    background-color: $nav-link-active-bg-color;
}

#sidebar .menu .menu-separator {
    text-transform: uppercase;
    color: $menu-separator-color;
    letter-spacing: 0.3px;
    font-weight: 500;
    font-size: 12px;
}

#sidebar .menu .accordion {
    width: 100%;
}

#sidebar:not(.sidebar-open-xl) .nav-link > span {
    opacity: 0;
    transition: opacity 0.25s ease-out;
}


@media (max-width: 991.98px) {
    #sidebar {
        width: 70px;
    }

    #sidebar:not(.sidebar-open-lg) .nav-link > span {
        opacity: 0;
        transition: opacity 0.25s ease-out;
    }

    #sidebar.sidebar-open-lg {
        width: 265px;
    }

    #sidebar.sidebar-open-lg .nav-link > span {
        opacity: 1!important;
        transition: opacity 0.25s ease-out;
    }

    #sidebar.sidebar-open-lg .menu .menu-separator span {
        opacity: 1;
    }

    #sidebar:not(.sidebar-open-lg) .menu .menu-separator span {
        opacity: 0;
    }

    #sidebar:not(.sidebar-open-lg) .menu .menu-separator svg {
        opacity: 1;
    }

}
@media (max-width: 767.98px) {
    #sidebar {
        min-height: auto;
        width: 100%!important;
    }

    #sidebar-toggle-lg {
        display: none;
    }

    #sidebar nav {
        flex-direction: row;
    }

    #top-menu-toggle {
        display: inline-block;
    }

    #sidebar .nav-link > span {
        opacity: 1!important;
    }

    #sidebar .menu .menu-separator span {
        opacity: 1!important;
    }

    #sidebar .menu .menu-separator svg {
        opacity: 0;
    }
}
