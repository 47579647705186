.custom-datepicker {
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;
    }
  }
}

.chip {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 20px 0.3rem 0.3rem 0;
}

.chip > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: 100;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
