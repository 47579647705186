.message-container>div {
  background: #ccc;
  color: #333;
  border-radius: 0.5em;
  padding: 0.5em;
  margin: 0.5em;
  max-width: 60%;
  display: inline-block;
}

.message-container.admin {
  text-align: right;
}

.message-container.admin>div {
  text-align: right;
  background: #bfbbf8;
}
